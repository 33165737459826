import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Agb from '../components/Agb'

export default ({ location, data, pageContext }) => (
  <Layout
    pageTitle={data.allContentfulPageAgb.edges[0].node.pageTitle}
    location={location}
    locale={pageContext.locale}
  >
    <Agb data={data.allContentfulPageAgb.edges[0].node} />
  </Layout>
)

export const agbQuery = graphql`
  query($locale: String) {
    allContentfulPageAgb(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          pageTitle
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
